<template>
  <b-card title="Instructor">
    <b-tabs>
      <b-tab
        active
        @click="$router.push('/create-instructor')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>New Instructor</span>
        </template>
        <AddInstructor @add-instructor="getData" />
      </b-tab>

      <b-tab 
      @click="$router.push('/instructors-list')" 
      >
        <template #title>
          <feather-icon icon="HomeIcon" data-testid="instructor-list" />
          <span>Instructor List</span>
        </template>
        <router-view />
        <!-- <InstructorList ref="allData"></InstructorList> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import AddInstructor from './AddInstructor.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

    AddInstructor,
  },
  setup() {
    const allData = ref()

    const getData = () => {
      //   console.log("Eee")

      allData.value.refetchData()
    }
    return {
      getData,

      allData,

    }
  },
}
</script>
